import { AbstractControl } from '@angular/forms'

export function adminPinCode(control: AbstractControl) {
  const controlValue = parseInt(control.value)

  if (controlValue === 2580) {
    return null
  }
  if (control.value === '') {
    return { required: true }
  }

  if ((controlValue + '').length < 4) {
    return { minlength: true }
  }

  if ((controlValue + '').length > 4) {
    return { maxlength: true }
  }

  return { adminPinCode: true }
}
