import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  NgZone,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren
} from '@angular/core'
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  skip,
  Subscription,
  switchMap,
  take,
  tap,
  timer
} from 'rxjs'
import LogRocket from 'logrocket'
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer'
import { Store } from '@ngxs/store'
import { ListItem } from 'design-system/lib/side-menu/side-menu.component'
import { AlertState } from '../../../../../store/alert/alert.state'
import { NavigationEnd, Router } from '@angular/router'
import { AlertDTO, AlertSeverity } from '../../../../../shared/model/alert'
import { EntityDictionary } from '@angular-ru/cdk/entity'
import { DepartmentState } from '../../../../../store/department/department.state'
import { RootStore } from '../../../../../store/root-store'
import {
  NzModalRef,
  NzModalComponent,
  NzModalContentDirective,
  NzModalTitleDirective
} from 'ng-zorro-antd/modal'
import { PreferenceState } from '../../../../../store/preference/preference.state'
import { UserState } from '../../../../../store/user/user.state'
import { UserInterface } from '../../../../../shared/model/user.model'
import { DepartmentDTO } from '../../../../../shared/model/permission.model'
import { environment } from '../../../../../environments/environment'
import { PccState } from '../../../../../store/pcc/pcc.state'
import { DOCUMENT, AsyncPipe } from '@angular/common'
import { AuthState } from 'projects/aiomed/src/store/auth/auth.state'
import { DepartmentType } from 'projects/aiomed/src/shared/model/departments.model'
import { CNA_SIDEMENU_ITEMS } from '../../constants/sidemenu-items.constant'
import { DeviceState } from 'projects/aiomed/src/store/device/device.state'
import { DeviceDTO } from 'projects/aiomed/src/shared/model/device.model'
import { NewVersionCheckerService } from 'projects/aiomed/src/shared/services/new-version-checker.service'
import { ErrorState } from 'projects/aiomed/src/store/error/error.state'
import {
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number'
import { adminPinCode } from '../../validators/admin-code.validator'
import { NzInputDirective } from 'ng-zorro-antd/input'
import moment from 'moment'
import { ReportState } from 'projects/aiomed/src/store/report/report.state'
import { NotificationState } from '../../../../../store/notification/notification.state'
import { NetworkService } from 'projects/aiomed/src/shared/services/network.service'
import { ConfigService } from 'projects/aiomed/src/shared/services/config.service'
import { FeatureParams } from 'projects/aiomed/src/shared/model/config'
import { SideMenuComponent } from 'design-system'
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch'
import { NzIconDirective } from 'ng-zorro-antd/icon'
import { NzButtonComponent } from 'ng-zorro-antd/button'
import { NzWaveDirective } from 'ng-zorro-antd/core/wave'
import { LoginTabletNurseComponent } from '../../../../pages/auth/components/login/login-tablet-nurse/login-tablet-nurse.component'
import { NzRowDirective, NzColDirective } from 'ng-zorro-antd/grid'
import { NzFormItemComponent, NzFormLabelComponent } from 'ng-zorro-antd/form'
import { NzSelectComponent, NzOptionComponent } from 'ng-zorro-antd/select'
import { NzCheckboxComponent } from 'ng-zorro-antd/checkbox'
import { UserPopoverComponent } from '../../../modals/components/user-popover/user-popover.component'
import { ReportIssueComponent } from '../../../modals/components/report-issue/report-issue.component'
import { WarningModalComponent } from '../../../mobile-ionic-components/component/warning-modal/warning-modal.component'
import { ReceivingDataDirective } from '../../receiving-data.directive'

@Component({
  selector: 'aiomed-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SideMenuComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzButtonComponent,
    NzWaveDirective,
    NzModalComponent,
    NzModalContentDirective,
    LoginTabletNurseComponent,
    NzModalTitleDirective,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzSelectComponent,
    ReactiveFormsModule,
    FormsModule,
    NzOptionComponent,
    NzCheckboxComponent,
    NzInputNumberComponent,
    UserPopoverComponent,
    ReportIssueComponent,
    WarningModalComponent,
    ReceivingDataDirective,
    AsyncPipe
  ]
})
export class MenuComponent implements OnInit, AfterViewInit {
  private readonly router = inject(Router)
  private readonly departmentState = inject(DepartmentState)
  private readonly ngZone = inject(NgZone)
  private readonly document = inject<Document>(DOCUMENT)
  private readonly authState = inject(AuthState)
  private readonly changeDetectorRef = inject(ChangeDetectorRef)
  private readonly reportState = inject(ReportState)
  private readonly networkService = inject(NetworkService)
  private readonly configService = inject(ConfigService)
  private readonly newVersionCheckerService = inject(NewVersionCheckerService)
  private readonly preferenceState = inject(PreferenceState)
  private readonly pccState = inject(PccState)
  private readonly destroyRef = inject(DestroyRef)

  @ViewChild('adminPinCodeField') adminPinCodeField: NzInputNumberComponent
  @ViewChildren(NzInputDirective) pccLoginInputs: QueryList<NzInputDirective>
  @Output('collapseMenuEmitter') collapseMenu = new EventEmitter()
  user$: Observable<UserInterface | null> = inject(Store).select(
    UserState.currentUser
  )
  userLoading$: Observable<boolean> = inject(Store).select(UserState.isLoading)
  isAuthLoading$: Observable<boolean> = inject(Store).select(
    AuthState.isLoading
  )
  devicesAlertsCount$: Observable<number> = inject(Store).select(
    RootStore.devicesAlertsCount
  )
  patientsAlertsCount$: Observable<number> = inject(Store).select(
    RootStore.patientsAlertsCount
  )
  unReadStatusCount$: Observable<number> = inject(Store).select(
    NotificationState.unReadStatus
  )
  alerts$: Observable<EntityDictionary<string, AlertDTO>> = inject(
    Store
  ).select(AlertState.alerts)
  preferenceSoundNotifications$: Observable<null | boolean> = inject(
    Store
  ).select(PreferenceState.preferenceSoundNotifications)
  isCollapsedMenu$: Observable<null | boolean> = inject(Store).select(
    PreferenceState.preferenceIsCollapseMenu
  )
  pccLoginWasNotPerformedButClosed$: Observable<boolean> = inject(Store).select(
    AuthState.pccLoginWasNotPerformedButClosed
  )
  allDepartments$: Observable<DepartmentDTO[]> = inject(Store).select(
    DepartmentState.allDepartments
  )
  currentDepartment$: Observable<DepartmentDTO | null> = inject(Store).select(
    DepartmentState.department
  )
  withoutDepartment$: Observable<boolean> = inject(Store).select(
    DepartmentState.withoutDepartment
  )
  currentUserDevice$ = inject(Store).select(DeviceState.currentUserDevice)
  secondaryDevicesIds$: Observable<string[] | null> = inject(Store).select(
    PreferenceState.secondaryDevicesIds
  )
  language$ = inject(Store).select(PreferenceState.language)
  isUserCNA$: Observable<boolean> = inject(Store).select(UserState.isUserCNA)
  isUserRN$: Observable<boolean> = inject(Store).select(UserState.isUserRN)
  hasCNAAccessToken$: Observable<boolean> = inject(Store).select(
    AuthState.hasCNAAccessToken
  )
  isPccLogging$: Observable<boolean> = inject(Store).select(
    AuthState.isPccLogging
  )
  isPCCAccountUsagePopupVisible$: Observable<boolean> = inject(Store).select(
    AuthState.isPCCAccountUsagePopupVisible
  )
  departmentType$ = inject(Store).select(DepartmentState.departmentType)
  errorMessage$: Observable<string | null> = inject(Store).select(
    ErrorState.errorMessage
  )
  incorrectTimeSystem$: Observable<boolean> = inject(Store).select(
    PreferenceState.incorrectTimeSystem
  )
  sharedDevices$: Observable<DeviceDTO[]> = inject(Store).select(
    DeviceState.sharedDevices
  )
  isForcedResolution$ = inject(Store).select(PreferenceState.isForcedResolution)
  isDeviceDirectConnection$: Observable<boolean | null> = inject(Store).select(
    PreferenceState.isDeviceDirectConnection
  )
  hasVirtualKeyboard$: Observable<boolean> = inject(Store).select(
    PreferenceState.hasVirtualKeyboard
  )
  userShouldWatchVideoTutorial = inject(Store).selectSignal(
    UserState.isShouldWatchVideoTutorial
  )
  hasVirtualKeyboard = inject(Store).selectSignal(
    PreferenceState.hasVirtualKeyboard
  )
  isPccUser$: Observable<boolean> = inject(Store).select(UserState.isPccUser)
  protected readonly isOffline$ = this.networkService.isOfflineDebounced$
  protected readonly isOnline$ = this.networkService.isOnlineObs$
  placement: NzDrawerPlacement = 'left'
  request: XMLHttpRequest = new XMLHttpRequest()
  context: AudioContext = new AudioContext()
  source: AudioBufferSourceNode
  timerSub: Subscription
  confirmModal?: NzModalRef
  source$: Observable<Event>
  popoverVisible: boolean = false
  reportIssueModalVisible: boolean = false
  public readonly DepartmentType = DepartmentType
  sourceClick$: Observable<Event> = fromEvent(window, 'click')
  sourceTouchmove$: Observable<Event> = fromEvent(window, 'mousemove')
  sourceTouchStart$: Observable<Event> = fromEvent(window, 'touchstart')
  public sideMenuItems$!: Observable<ListItem[]>
  public isShowWarning: boolean = false
  public isPccTokenInvalid: boolean = false
  public isDeviceChoosing: boolean = false
  public isLogoutConfirmModalOpened: boolean = false
  public isMobile = this.preferenceState.isMobile
  public readonly currentVersionTimestamp =
    this.newVersionCheckerService.getCurrentVersionTimestamp
  public passwordVisible: boolean = false
  public selectedDeviceId: string = ''
  public selectedDepartmentId: string = ''
  public selectedSecondaryDevicesIds: string[] = []
  public hasFocus = signal<boolean>(false)
  loginWithCred: boolean = false
  protected isShowDevicePairing: boolean = false
  private deviceUpdateSubject = new BehaviorSubject<boolean>(false)
  readonly deviceUpdate$ = this.deviceUpdateSubject.asObservable()
  private readonly formBuilder = inject(FormBuilder)
  public adminPinCode = this.formBuilder.control('', {
    validators: [adminPinCode],
    updateOn: 'change'
  })
  public sendPCCUsageConsent = this.formBuilder.group({
    confirmPassword: this.formBuilder.control('', {
      validators: [Validators.required, Validators.minLength(6)]
    }),
    allow: this.formBuilder.control(false, {
      validators: [Validators.required]
    })
  })
  private readonly sessionExpirationDuration =
    this.configService.getNumberValue(
      FeatureParams.FeatureSessionExpirationDuration,
      30
    )
  private readonly screenSaverDebounceTime = this.configService.getNumberValue(
    FeatureParams.FeatureScreenSaverDebounceTime,
    5
  )

  get allow() {
    return this.sendPCCUsageConsent.get('allow')
  }

  get confirmPassword() {
    return this.sendPCCUsageConsent.get('confirmPassword')
  }

  ngOnInit(): void {
    this.initializeListeners()
    this.setAudioContext()
    this.localStoreListener()
    this.ngZone.runOutsideAngular(() => {
      this.subscribeOnWindowsClickAndTouchmove().subscribe()
      if (this.isMobile) {
        return this.detectRnSessionExpiration().subscribe()
      } else {
        return this.detectSessionExpiration().subscribe()
      }
    })
    this.setSidenavItems()
    this.detectUserRoleChanges()
    this.setUserPasswordAfterPCCLogin()
    this.incorrectTimeSystemListener()
    this.setCurrentUserDeviceAndDepartmentIds()
    if (this.isMobile) {
      this.handleTabletPccLoginFocusOut()
    }
  }

  ngAfterViewInit(): void {
    // trigger for screen saver to appear if no interactions happened
    this.document.documentElement.click()
  }

  subscribeOnWindowsClickAndTouchmove() {
    return merge(
      this.sourceClick$,
      this.sourceTouchmove$,
      this.sourceTouchStart$,
      this.deviceUpdate$.pipe(filter(value => value))
    ).pipe(
      switchMap(state => {
        const timerTime = moment().add(this.screenSaverDebounceTime, 'minutes')
        return state ? timer(timerTime.toDate()) : of(null)
      }),
      tap(() => {
        if (!this.userShouldWatchVideoTutorial()) {
          this.preferenceState.setScreenSever(true)
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    )
  }

  localStoreListener() {
    this.source$ = fromEvent(window, 'storage')
    this.source$
      .pipe(delay(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((data: Event) => {
        if (data instanceof StorageEvent) {
          if (
            data.key === null ||
            (data.key === 'auth.accessJwt' &&
              data.newValue === null &&
              data.oldValue != null)
          ) {
            // this.systemLogOut()
          }
        }
      })
  }

  initializeListeners(): void {
    this.preferenceSoundNotifications$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (!data || !environment.production) return
        this.setMediaSourceWhenHaveCriticalAlert()
      })
    this.withoutDepartment$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(i => {
        if (!i) return
        // this.handlerClickLogout()
      })
  }

  setMediaSourceWhenHaveCriticalAlert(): void {
    this.alerts$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((alerts: EntityDictionary<string, AlertDTO>) => {
        const alertsDTO: AlertDTO[] = Object.values(alerts)
        const alert: AlertDTO | undefined = alertsDTO.find(
          alert => alert.severity === AlertSeverity.Critical
        )
        if (!alert || !alertsDTO.length) {
          if (this.timerSub) {
            this.timerSub.unsubscribe()
            this.request.abort()
          }
          return
        }
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.timerSub = timer(0, 50000)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(() => {
                this.source.start(0)
                setTimeout(() => {
                  this.source.stop(0)
                  this.request.abort()
                  this.setAudioContext()
                }, 2000)
              })
          })
          .catch(error => console.log(error))
      })
  }

  setAudioContext() {
    this.source = this.context.createBufferSource()
    this.source.connect(this.context.destination)
    this.request.open('GET', 'assets/door_bell.aac', true)
    this.request.timeout = 10000
    this.request.responseType = 'arraybuffer'
    this.request.onload = () => {
      this.context.decodeAudioData(
        this.request.response,
        response => {
          this.source.buffer = response
          this.source.loop = true
        },
        function () {
          console.error('The request failed.')
        }
      )
    }
    this.request.send()
  }

  close(): void {
    this.collapseMenu.emit(false)
  }

  handlerChangeDepartment(departmentId: string) {
    this.preferenceState.setPreferenceDepartmentId(departmentId)
  }

  handlerClickLogout() {
    // this.pccState.pccLogout()
    // this.systemLogOut()
    this.hasFocus.set(false)
    this.pccState
      .pccLogout()
      .pipe(
        finalize(() => {
          this.systemLogOut()
        })
      )
      .subscribe()
  }

  public openConfirmLogout(): void {
    combineLatest([this.isUserCNA$, this.isPccUser$])
      .pipe(take(1))
      .subscribe(([isUserCNA, isPccUser]) => {
        if (isUserCNA && !isPccUser) {
          this.isLogoutConfirmModalOpened = true

          timer(150)
            .pipe(take(1))
            .subscribe(() => {
              this.adminPinCodeField.focus()
            })
        } else {
          this.handlerClickLogout()
        }
      })
  }

  public handlerClickRnLogout(): void {
    this.pccState.pccLogout().subscribe(() => {
      this.authState.rnLogout()
      this.preferenceState.setPreferenceIsCollapseMenu(true)
    })
  }

  adminCodeFocus() {
    this.hasFocus.set(true)
    this.changeDetectorRef.markForCheck()
  }

  adminCodeBlur() {
    setTimeout(() => {
      if (
        !this.document.activeElement ||
        this.document.activeElement.tagName !== 'INPUT'
      ) {
        this.hasFocus.set(false)
        this.changeDetectorRef.detectChanges()
      }
    }, 50)
  }

  systemLogOut() {
    this.departmentState.logout()
  }

  handlerChangeLanguageEmitter($event: string) {
    this.preferenceState.setLanguage($event)
  }

  openReportIssueModal() {
    this.reportIssueModalVisible = true
    this.popoverVisible = false
  }

  closeReportIssueModal() {
    this.reportIssueModalVisible = false
  }

  reportIssueToLogRocket(event: string) {
    this.closeReportIssueModal()
    LogRocket.captureMessage(event, {
      tags: {
        userReport: 'user-report'
      }
    })
  }

  public itemClick(): void {
    this.collapseMenu.emit(true)
  }

  public showPopover(): void {
    this.popoverVisible = true
  }

  public toggleSidenav(event: boolean): void {
    this.collapseMenu.emit(event)
  }

  public showModalLoginToPcc(): void {
    this.authState.setIsPccLogging(true)
  }

  public hideModalLoginToPcc(): void {
    this.authState.setIsPccLogging(false)
    this.hasFocus.set(false)
    this.authState.setIsLoading(false)
    this.closeOpenedWindow()
  }

  public closeOpenedWindow(): void {
    this.authState.closeOpenedWindow()
  }

  public handlerFormSubmitEmitter(credentials: {
    username: string
    password: string
  }): void {
    this.handleRnLogin(credentials)
    this.hasFocus.set(false)
  }

  public reLoginToRnMode(): void {
    this.authState.setIsPccLogging(true)
    this.showModalLoginToPcc()
    this.isShowWarning = false
  }

  public refreshPage(): void {
    location.reload()
  }

  public openPccLoginWindow(): void {
    this.authState.openPccLoggingWindowAfterInitialLogin(
      this.authState.getState().accessJwt?.token!
    )
    this.isPccTokenInvalid = false
  }

  public loginWithPcc(): void {
    this.authState.loginWithPcc('tablet')
  }

  public hidePCCAccountUsagePopup(): void {
    this.authState.setIsPCCAccountUsagePopupVisible(false)
  }

  public allowPCCAccountUsage(): void {
    this.authState.allowPccAccountUsage()
    this.authState.setIsPCCAccountUsagePopupVisible(false)
  }

  public pairDevices(): void {
    this.isShowDevicePairing = true
    setTimeout(() => {
      this.adminPinCodeField.focus()
    }, 500)
  }

  public hideDevicePairing(): void {
    this.isShowDevicePairing = false
    this.isDeviceChoosing = false
    this.isLogoutConfirmModalOpened = false
    this.adminPinCode.reset()
    this.adminPinCode.updateValueAndValidity()
    this.setCurrentUserDeviceAndDepartmentIds()
  }

  public showDeviceChoosing(): void {
    this.isDeviceChoosing = true
    this.hasFocus.set(false)
  }

  public showSpotlightTable(): void {
    this.reportState.getTabletSpotlightPatients()
  }

  public setCurrentDeviceAndDepartmentIds(): void {
    this.preferenceState.setCurrentDeviceId(this.selectedDeviceId)
    this.departmentState.setCurrentDepartment(this.selectedDepartmentId)
    if (this.selectedSecondaryDevicesIds) {
      this.preferenceState.setSecondaryDevicesIds(
        this.selectedSecondaryDevicesIds
      )
    }
    this.isDeviceChoosing = false
    this.hideDevicePairing()

    this.user$.pipe(take(1)).subscribe(user => {
      if (!user?.id) {
        return
      }

      const logrocketTrackProperties = {
        name: user.name.firstName + ' ' + user.name.lastName,
        email: user.email!,
        _degree: user._degree || '',
        mainDevice: this.preferenceState.getState().deviceId || '',
        devices:
          this.preferenceState.getState().secondaryDevicesIds?.join(',') || '',
        onDutyDepartment: this.preferenceState.getState().department?.name || ''
      }
      LogRocket.identify(user?.id, logrocketTrackProperties)
      LogRocket.track('user', logrocketTrackProperties)
      LogRocket.log('user', logrocketTrackProperties)
    })
    this.collapseMenu.emit(true)
  }

  public handleEnterClick(event: Event): void {
    if (!this.adminPinCode.valid) {
      return
    }
    if (!this.isLogoutConfirmModalOpened) {
      this.showDeviceChoosing()
      return
    }
    this.handlerClickLogout()
  }

  public setDirectDeviceConnection(isDirect: boolean): void {
    this.preferenceState.setDeviceDirectConnection(isDirect)
  }

  handlerDeviceUpdatesEmitter() {
    this.deviceUpdateSubject.next(true)
  }

  private detectSessionExpiration() {
    return merge(
      this.sourceClick$,
      this.sourceTouchmove$,
      this.sourceTouchStart$
    ).pipe(
      switchMap(state => {
        const timerTime = moment().add(
          this.sessionExpirationDuration,
          'minutes'
        )
        return state ? timer(timerTime.toDate()) : of(null)
      }),
      tap(() => {
        this.preferenceState.setSessionExpire(true)
        this.handlerClickLogout()
      }),
      takeUntilDestroyed(this.destroyRef)
    )
  }

  private detectRnSessionExpiration() {
    return merge(
      this.sourceClick$,
      this.sourceTouchmove$,
      this.sourceTouchStart$
    ).pipe(
      switchMap(state => {
        const timerTime = moment().add(
          this.sessionExpirationDuration,
          'minutes'
        )
        return state ? timer(timerTime.toDate()) : of(null)
      }),
      switchMap(() =>
        combineLatest([this.isUserRN$, this.isUserCNA$, this.isPccUser$]).pipe(
          take(1)
        )
      ),
      switchMap(([isUserRN, isUserCNA, isPccUser]) => {
        if (isUserCNA && isPccUser) {
          this.preferenceState.setSessionExpire(true)
          this.handlerClickLogout()
          return of(null)
        }
        if (isUserRN) {
          return this.pccState.getPccIsAuthStatus(true).pipe(
            catchError(() => {
              this.preferenceState.setSessionExpire(true)
              this.handlerClickLogout()
              return EMPTY
            })
          )
        }
        return of(null)
      }),
      switchMap(token =>
        this.isOnline$.pipe(
          filter(isOnline => isOnline),
          debounceTime(4000),
          distinctUntilChanged(),
          take(1),
          map(() => token)
        )
      ),
      switchMap(token => {
        if (token) {
          return of(token)
        }
        if (this.authState.getState().CNAAccessJwt) {
          this.handlerClickRnLogout()
          this.isShowWarning = true
        }
        return of(null)
      }),
      tap(pccTokenStatus => {
        if (pccTokenStatus !== null && !this.isShowWarning) {
          this.preferenceState.setSessionExpire(true)
          this.handlerClickLogout()
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    )
  }

  private setSidenavItems(): void {
    this.sideMenuItems$ = combineLatest([
      this.departmentType$,
      this.patientsAlertsCount$,
      this.devicesAlertsCount$,
      this.user$,
      this.unReadStatusCount$
    ]).pipe(
      map(
        ([
          departmentType,
          patientAlertsCount,
          devicesAlertsCount,
          user,
          unReadStatusCount
        ]) => {
          let items = []

          if (
            user?._degree === 'MEDICAL_ASSISTANT' ||
            user?._degree === 'NO_DEGREE'
          ) {
            this.navigateToVitalsIfCNA(user)
            return CNA_SIDEMENU_ITEMS
          }

          if (this.isMobile) {
            return CNA_SIDEMENU_ITEMS
          }

          if (departmentType === DepartmentType.Automatic) {
            items = [
              {
                icon: 'home',
                title: 'nav.home',
                link: '',
                badgeCount: patientAlertsCount,
                exact: true
              },
              {
                icon: 'icons:nav-shift-planer',
                title: 'Shift Planner',
                link: '/shift-planer'
              },
              {
                icon: 'aio:patients',
                title: 'nav.allPatients',
                link: '/all-patients',
                badgeCount: patientAlertsCount
              },
              {
                icon: 'aio:devices',
                title: 'nav.device',
                link: '/devices',
                badgeCount: devicesAlertsCount
              },
              {
                icon: 'aio:insights-not-filled',
                title: 'nav.insights',
                link: '/insights'
              },
              {
                icon: 'aio:alert',
                title: 'nav.alerts',
                link: '/alerts',
                badgeCount: patientAlertsCount
              },
              { icon: 'aio:vitals', title: 'nav.vitals', link: '/reports' },
              {
                icon: 'aio:bell',
                link: '/notifications',
                isNFType: true,
                badgeCount: unReadStatusCount
              }
            ]
          } else {
            items = [
              {
                icon: 'home',
                title: 'nav.home',
                link: '',
                badgeCount: patientAlertsCount,
                exact: true
              },
              {
                icon: 'icons:nav-shift-planer',
                title: 'Shift Planner',
                link: '/shift-planer'
              },
              {
                icon: 'aio:patients',
                title: 'nav.allPatients',
                link: '/all-patients',
                badgeCount: patientAlertsCount
              },
              {
                icon: 'aio:insights-not-filled',
                title: 'nav.insights',
                link: '/insights'
              },
              { icon: 'aio:vitals', title: 'nav.vitals', link: '/reports' },
              {
                icon: 'aio:bell',
                link: '/notifications',
                isNFType: true,
                badgeCount: unReadStatusCount
              }
            ]
          }

          return items
        }
      )
    )
  }

  private navigateToVitalsIfCNA(user: UserInterface | null): void {
    if (user && this.router.url !== '/reports') {
      this.router.navigate(['/reports'], { queryParamsHandling: 'merge' })
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (user && this.router.url !== '/reports') {
          this.router.navigate(['/reports'], { queryParamsHandling: 'merge' })
        }
      })
  }

  private detectUserRoleChanges(): void {
    if (!this.isMobile) {
      return
    }
    combineLatest([this.user$, this.isUserCNA$])
      .pipe(
        filter(([user]) => !!user),
        skip(1),
        tap(([user, isUserCNA]) => {
          if (!user) {
            return
          }
          if (!isUserCNA) {
            this.hideModalLoginToPcc()
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
  }

  private handleRnLogin(credentials: {
    username: string
    password: string
  }): void {
    this.authState.rnLogin(credentials)
  }

  private setUserPasswordAfterPCCLogin(): void {
    this.authState.rnPasswordObservable$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(password => {
        this.confirmPassword?.setValue(password)
        this.allow?.setValue(false)
      })
  }

  private setCurrentUserDeviceAndDepartmentIds(): void {
    this.selectedDeviceId = ''
    this.selectedDepartmentId = ''
    this.selectedSecondaryDevicesIds = []

    this.currentUserDevice$.pipe(take(1)).subscribe(currentUserDevice => {
      if (currentUserDevice && currentUserDevice.id !== '-') {
        this.selectedDeviceId = currentUserDevice.id
      }
    })
    this.currentDepartment$
      .pipe(
        filter(v => !!v),
        take(1)
      )
      .subscribe(currentDepartment => {
        if (currentDepartment && currentDepartment.id !== '-') {
          this.selectedDepartmentId = currentDepartment.id
        }
      })
    this.secondaryDevicesIds$
      .pipe(take(1))
      .subscribe(selectedSecondaryDevicesIds => {
        if (selectedSecondaryDevicesIds && selectedSecondaryDevicesIds.length) {
          this.selectedSecondaryDevicesIds = [...selectedSecondaryDevicesIds]
        }
      })
  }

  private incorrectTimeSystemListener() {
    this.incorrectTimeSystem$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (!data) return
        this.handlerClickLogout()
      })
  }

  private handleTabletPccLoginFocusOut(): void {
    this.pccLoginWasNotPerformedButClosed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(pccLoginWasNotPerformedButClosed => {
        if (pccLoginWasNotPerformedButClosed) {
          this.pccLoginInputs.forEach(inp =>
            inp['elementRef'].nativeElement.blur()
          )
          this.hasFocus.set(false)
          this.changeDetectorRef.detectChanges()
        }
      })
  }
}
