<nz-modal
  nzNoAnimation
  [nzDraggable]="false"
  [nzVisible]="isVisible()"
  [nzFooter]="null"
  nzCentered
  nzClassName="sign-disclaimer"
  nzWrapClassName="sign-disclaimer__wrapper"
  [nzWidth]="isMobile() ? 1140 : 631"
  [nzBodyStyle]="
    isMobile()
      ? { padding: '48px 211px', display: 'flex', 'flex-direction': 'column' }
      : { padding: '0', 'max-height': '711px', height: 'auto' }
  ">
  <ng-container *nzModalContent>
    <div class="sign-disclaimer__header" [class.desktop]="!isMobile()">
      <h3 class="sign-disclaimer__title" [class.desktop]="!isMobile()">
        Disclaimer
      </h3>
      <p class="sign-disclaimer__description" [class.desktop]="!isMobile()">
        Please read and accept the disclaimer to continue using the system. If
        you decline, you will be logged out.
      </p>
    </div>
    <div
      #content
      class="sign-disclaimer__content"
      [class.desktop]="!isMobile()"
      [class.scrollable]="isScrollable()"
      (scroll)="checkScrolledBottom($event)"
      [attr.data-cy]="'sign-disclaimer__content'">
      By using this Platform, you understand and agree that it is a supportive
      tool intended to assist in patient care but does not replace your
      responsibility to independently evaluate, assess, and monitor your
      patients. While the Platform provides information and alerts to aid
      clinical decision-making, all decisions regarding patient care remain
      solely the responsibility of the medical institution and the user of the
      Platform. As such, AiOmed Inc. assumes no liability for any outcomes
      arising from the use of the Platform, including errors or omissions.
    </div>
    <div class="sign-disclaimer__actions" [class.desktop]="!isMobile()">
      <button (click)="decline.emit()" class="button-secondary">Decline</button>
      <button
        (click)="accept.emit()"
        [disabled]="!isScrolledBottom() && isScrollable()"
        class="button-primary"
        [attr.data-cy]="'sign-disclaimer__accept'">
        Accept
      </button>
    </div>
  </ng-container>
</nz-modal>
