<nz-layout aiomedChargeNotifier class="layout">
  @if (hasNotificationAccess$ | async) {
    @if ((urlLogin$ | async) && (isAuthorized$ | async)) {
      <nz-sider
        [class.desktop-forced]="(isForcedResolution$ | async) === false"
        [class.tablet-forced]="(isForcedResolution$ | async) === true"
        [class.tablet]="isMobile"
        class="nz-slider"
        nzTheme="light"
        nzWidth="96px">
        <aiomed-menu
          (collapseMenuEmitter)="
            handlerCollapseMenuEmitter($event)
          "></aiomed-menu>
      </nz-sider>
    }
    <nz-layout
      [class.desktop-forced]="(isForcedResolution$ | async) === false"
      [class.right-layout]="isAuthorized$ | async"
      [class.tablet-forced]="(isForcedResolution$ | async) === true"
      [class.tablet]="isMobile">
      <nz-content>
        <main>
          <router-outlet></router-outlet>
          <ng-container #testModeContainer></ng-container>
        </main>
      </nz-content>
    </nz-layout>
  } @else {
    <nz-content>
      <ng-container
        *ngTemplateOutlet="notificationAccessContent"></ng-container>
    </nz-content>
  }
</nz-layout>
@if (screenSaver$ | async) {
  <aiomed-screen-saver-modal
    [isAuthenticated]="isAuthorized$ | async"
    [isMobile]="isMobile"
    [hasVirtualKeyboard]="hasVirtualKeyboard()"
    [screenBlockedStatus]="screenBlockedStatus$ | async"
    (submitEmitter)="
      handlerScreenSaverSubmitEmitter()
    "></aiomed-screen-saver-modal>
}
@if ((isSessionExpire$ | async) && !(isOffline$ | async)) {
  <aiomed-warning-modal
    [isSessionExpired]="true"
    [isMobile]="isMobile"
    [width]="595"
    [zIndex]="1010"
    (closeModal)="hideModal()"
    (saveModalDataEmitter)="hideModal()"
    closeText="Close"
    description="You've been logged out due to inactivity. Please log in again to continue"
    saveText="Log in"
    title="YOU’VE BEEN LOGGED OUT"></aiomed-warning-modal>
}
@if (pccLoginSettingsTabletErrorShown$ | async; as errorShown) {
  <aiomed-warning-modal
    [title]="'LOGIN SETTINGS'"
    [descriptionTemplate]="descriptionTemplate"
    [zIndex]="1001"
    [isLoading]="false"
    [hideSave]="true"
    [closeText]="'OK'"
    [width]="800"
    (closeModal)="hidePccLoginSettingsTabletError()"></aiomed-warning-modal>
  <ng-template #descriptionTemplate>
    <span
      >PointClickCare login is not enabled for your account.
      <strong>Please log in with your AiOmed user first.</strong> Contact your
      administrator to enable PointClickCare login for future use.</span
    >
    <div class="login-settings__error" style="margin-top: 26px">
      <nz-collapse nzGhost>
        <nz-collapse-panel
          nzHeader="Technical details"
          [nzActive]="pccLoginErrorTechnicalDetailsVisible">
          {{ errorShown }}
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </ng-template>
}
@if ((pccLoginWasNotPerformedButClosed$ | async) && !(isOffline$ | async)) {
  <aiomed-warning-modal
    [isMobile]="isMobile"
    [title]="'EMR LOG IN REQUIRED'"
    [description]="
      'To report to PointClickCare, you must first log in. Please log in to perform actions.'
    "
    [zIndex]="1001"
    [isLoading]="false"
    [saveText]="'Log in'"
    [closeText]="'Close'"
    [width]="595"
    (closeModal)="pccLoginWasNotPerformedButClosed()"
    (saveModalDataEmitter)="handlerLoginWithPcc()"></aiomed-warning-modal>
  <ng-template #descriptionTemplate>
    <span
      >PointClickCare login is not enabled for your account.
      <strong>Please log in with your AiOmed user first.</strong> Contact your
      administrator to enable PointClickCare login for future use.</span
    >
  </ng-template>
}
@if (pccLoginWasPerformedWithCNAUser$ | async) {
  <aiomed-warning-modal
    [title]="'ACCESS LIMITED TO AUTHORISED ACCOUNTS'"
    [isMobile]="isMobile"
    description="We're sorry, but only users classified as 'Nurse' can access our system via PCC. If you believe this is an error or need assistance with your account, please contact your administrator for support"
    [zIndex]="1001"
    [isLoading]="false"
    [hideSave]="true"
    [closeText]="'Close'"
    [width]="isMobile ? 800 : 863"
    (closeModal)="
      pccLoginWasPerformedWithCNAUserClose()
    "></aiomed-warning-modal>
}

<aiomed-report-loader
  [isDark]="true"
  [loader]="
    (pccLogouting$ | async) || pccLoginReturning()
  "></aiomed-report-loader>
@if (incorrectTimeSystem$ | async) {
  <aiomed-clock-update-modal
    (closeModal)="
      handlerCloseClockUpdateModal($event)
    "></aiomed-clock-update-modal>
}

@defer (when isMobile) {
  @if ((isOffline$ | async) && isMobile) {
    <aiomed-wifi-instruction [isVisible]="isOffline$ | async" />
  }
}

@if (userShouldWatchDisclaimer()) {
  <aiomed-sign-disclaimer
    [isVisible]="!(isOffline$ | async) && userShouldWatchDisclaimer()"
    [isMobile]="isMobile"
    (decline)="declineDisclaimerLogout()"
    (accept)="acceptDisclaimer()" />
}

<ng-template #notificationAccessContent>
  <section class="notification-access">
    <div class="notification-access__content">
      <i nz-icon nzType="icons:notification-access"></i>
      <div class="notification-access__title">
        Mobile Access Not Yet Available
      </div>
      <div class="notification-access__text">
        Our system is currently accessible only on desktop devices. Mobile
        support is coming soon. Please use a desktop to view your notification.
      </div>
      <div class="notification-access__bottom-text">
        Have questions?<br />
        support&#64;aiomed.com
      </div>
    </div>
  </section>
</ng-template>
