import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  output,
  signal,
  viewChild
} from '@angular/core'
import { NzModalModule } from 'ng-zorro-antd/modal'

@Component({
  selector: 'aiomed-sign-disclaimer',
  imports: [NzModalModule],
  templateUrl: './sign-disclaimer.component.html',
  styleUrl: './sign-disclaimer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignDisclaimerComponent {
  content = viewChild<ElementRef<HTMLDivElement>>('content')
  isVisible = input<boolean>(false)
  isMobile = input<boolean>(false)
  accept = output<void>()
  decline = output<void>()
  isScrolledBottom = signal<boolean>(false)
  isScrollable = computed(() => {
    if (this.content()) {
      return this.checkIfScrolalble(this.content()?.nativeElement)
    }
    return false
  })

  public checkScrolledBottom(event: Event): void {
    const target = event.target as HTMLDivElement
    if (!target) {
      return
    }
    if (target.scrollHeight <= target.scrollTop + target.offsetHeight) {
      this.isScrolledBottom.set(true)
    }
  }

  private checkIfScrolalble(element: HTMLDivElement | undefined) {
    if (!element) {
      return false
    }
    const hasScrollableContent = element.scrollHeight > element.clientHeight
    return hasScrollableContent
  }
}
