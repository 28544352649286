import {
  ActionType,
  getActionTypeFromInstance,
  NgxsNextPluginFn,
  NgxsPlugin,
  withNgxsPlugin
} from '@ngxs/store'
import LogRocket from 'logrocket'
import {
  Inject,
  Injectable,
  InjectionToken,
  makeEnvironmentProviders
} from '@angular/core'
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs'

export const NGXS_LOGROCKET_PLUGIN_OPTIONS = new InjectionToken(
  'NGXS_LOGROCKET_PLUGIN_OPTIONS'
)

@Injectable()
export class LogRocketPlugin implements NgxsPlugin {
  reduxMiddleware: any
  currentState: any = null
  store: any = {}

  constructor(@Inject(NGXS_LOGROCKET_PLUGIN_OPTIONS) private options: any) {
    if (window.location.hostname !== 'localhost') {
      this.reduxMiddleware = LogRocket.reduxMiddleware({
        stateSanitizer: function (state) {
          const MAX_STATE_SIZE = 10000
          const stateSize = new TextEncoder().encode(
            JSON.stringify(state)
          ).length

          if (stateSize > MAX_STATE_SIZE) {
            return {
              ...state,
              alert: undefined
            }
          }
          return state
        }
      })

      this.store = this.reduxMiddleware({
        getState: () => this.currentState
      })
    }
  }

  handle(state: any, action: ActionType, next: NgxsNextPluginFn) {
    const type = getActionTypeFromInstance(action)
    let n: Observable<any> = next(state, action)
    return n.pipe(
      catchError(error => {
        if (
          (error &&
            error.stack &&
            error.stack.includes('patients_module_ts.js')) ||
          (error.error &&
            error.error.message &&
            error.error.message.includes('createInvalidObservableTypeError')) ||
          (error.error &&
            error.error.message &&
            error.error.message.includes('IconNotFoundError')) ||
          (error.error &&
            error.error.message &&
            error.error.message.includes('ShiftPlanerListComponent_Template'))
        ) {
          return EMPTY
        }
        return throwError(error)
      }),
      tap(nextState => {
        this.currentState = { ...state }
        // @ts-ignore
        let a = { type, ...action }
        if (typeof this.store === 'function') {
          this.store(() => nextState)(a)
        }
      })
    )
  }
}

export function withNgxsLogrocketPlugin(options?: any) {
  return makeEnvironmentProviders([
    withNgxsPlugin(LogRocketPlugin),
    {
      provide: NGXS_LOGROCKET_PLUGIN_OPTIONS,
      useValue: options
    }
  ])
}
