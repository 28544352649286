import { NgTemplateOutlet } from '@angular/common'
import { Component, input } from '@angular/core'
import { NzModalModule } from 'ng-zorro-antd/modal'
import {
  WIFI_HOLD_BUTTON,
  WIFI_RESTART
} from 'projects/aiomed/src/shared/constants/offline-images.constant'

@Component({
  selector: 'aiomed-wifi-instruction',
  imports: [NzModalModule, NgTemplateOutlet],
  templateUrl: './wifi-instruction.component.html',
  styleUrl: './wifi-instruction.component.scss'
})
export class WifiInstructionComponent {
  isVisible = input<boolean | null>(false)
  protected readonly wifiHoldButtonImage = WIFI_HOLD_BUTTON
  protected readonly wifiRestartImage = WIFI_RESTART
}
