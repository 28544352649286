import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

interface BatteryManager extends EventTarget {
  charging: boolean
  chargingTime: number
  dischargingTime: number
  level: number
  onchargingchange: ((this: BatteryManager, ev: Event) => any) | null

  addEventListener(
    type: 'chargingchange',
    listener: (this: BatteryManager, ev: Event) => any,
    options?: boolean | AddEventListenerOptions
  ): void

  removeEventListener(
    type: 'chargingchange',
    listener: (this: BatteryManager, ev: Event) => any,
    options?: boolean | EventListenerOptions
  ): void
}

interface Navigator {
  getBattery(): Promise<BatteryManager>
}

@Injectable()
export class BatteryService {
  private batteryRef: BatteryManager | null = null
  private chargingSubject = new BehaviorSubject<boolean>(false)
  readonly charging$ = this.chargingSubject.asObservable()

  async init() {
    if ('getBattery' in navigator) {
      this.batteryRef = await (navigator as Navigator).getBattery()
      this.chargingSubject.next(this.batteryRef!.charging)
      this.batteryRef!.addEventListener('chargingchange', () => {
        this.chargingSubject.next(this.batteryRef!.charging)
      })
    } else {
      console.log('Battery API not supported in this browser')
    }
  }

  isCharging(): boolean {
    return this.batteryRef ? this.batteryRef.charging : false
  }
}
