import { ErrorHandler, Injectable } from '@angular/core'

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  handleError(error: Error): void {
    if (
      error.message?.includes('Failed to fetch dynamically imported module') ||
      (error.message?.includes('Loading chunk') &&
        error.message?.includes('failed'))
    ) {
      window.location.reload()
    }
  }
}
