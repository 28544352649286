import {
  afterNextRender,
  Directive,
  inject,
  OnDestroy,
  OnInit,
  signal
} from '@angular/core'
import { BatteryService } from '../../shared/services/battery.service'
import {
  delay,
  first,
  fromEvent,
  interval,
  merge,
  Subject,
  take,
  takeUntil,
  tap,
  timer
} from 'rxjs'
import LogRocket from 'logrocket'
import { PreferenceState } from '../../store/preference/preference.state'
import { ConfigService } from '../../shared/services/config.service'
import { FeatureParams } from '../../shared/model/config'

@Directive({
  selector: '[aiomedChargeNotifier]',
  standalone: true,
  providers: [BatteryService]
})
export class ChargeNotifierDirective implements OnInit, OnDestroy {
  private readonly batteryService = inject(BatteryService)
  private readonly preferenceState = inject(PreferenceState)
  private configService = inject(ConfigService)
  private IDLE_THRESHOLD: number
  private NOTIFICATION_PERIOD: number
  private readonly DEFAULT_IDLE_THRESHOLD = 3600000
  private readonly DEFAULT_NOTIFICATION_PERIOD = 300000
  private chargeMeAudio = signal(new Audio('assets/charge-me.mp3'))
  private thankYouAudio = signal(new Audio('assets/thank-you.mp3'))
  private lastActivityTime = signal(Date.now())
  private audioUnlocked = signal(false)
  private destroy$ = new Subject<void>()
  private chargeMeAudioPlayed = signal(false)
  private readonly isMobile = this.preferenceState.isMobile
  private readonly featureEnabled = this.configService.isFeatureEnabled(
    FeatureParams.FeatureNotifierEnabled
  )

  constructor() {
    afterNextRender(() => {
      if (!this.isMobile) return
      if (!this.featureEnabled) return
      this.setUnlockAudio()
    })
  }

  async ngOnInit() {
    if (!this.isMobile) return
    if (!this.featureEnabled) return
    await this.innitButtery()
  }

  async innitButtery() {
    await this.batteryService.init()
    this.batteryService.charging$
      .pipe(
        takeUntil(this.destroy$),
        tap(charging => this.handleChargingChange(charging))
      )
      .subscribe()
  }

  setUnlockAudio() {
    this.chargeMeAudio().loop = false
    this.thankYouAudio().loop = false
    fromEvent(document, 'touchstart')
      .pipe(
        takeUntil(this.destroy$),
        first(),
        delay(2000),
        tap(() => {
          this.unlockAudio()
        })
      )
      .subscribe()
  }

  notificationPeriodListener() {
    this.NOTIFICATION_PERIOD = this.configService.getNumberValue(
      FeatureParams.FeatureNotifierTimeRepetition,
      this.DEFAULT_NOTIFICATION_PERIOD
    )
    interval(this.NOTIFICATION_PERIOD)
      .pipe(
        tap(() => this.checkAndNotify()),
        takeUntil(this.destroy$)
      )
      .subscribe()
  }

  activityEventsListener() {
    const activityEvents = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'touchstart')
    )
    activityEvents
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.resetIdleTimer())
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
    this.stopAllAudio()
  }

  private resetIdleTimer() {
    this.lastActivityTime.set(Date.now())
  }

  private unlockAudio() {
    Promise.allSettled([this.chargeMeAudio(), this.thankYouAudio()])
      .then(() => {
        this.chargeMeAudio().pause()
        this.chargeMeAudio().currentTime = 0
        this.thankYouAudio().pause()
        this.thankYouAudio().currentTime = 0
        this.audioUnlocked.set(true)
        this.notificationPeriodListener()
        this.activityEventsListener()
      })
      .catch(err => {
        console.warn(err)
      })
  }

  private checkAndNotify() {
    const inactiveTime = Date.now() - this.lastActivityTime()
    this.IDLE_THRESHOLD = this.configService.getNumberValue(
      FeatureParams.FeatureNotifierTime,
      this.DEFAULT_IDLE_THRESHOLD
    )

    if (
      !this.batteryService.isCharging() &&
      inactiveTime >= this.IDLE_THRESHOLD
    ) {
      this.showChargeNotification()
      if (typeof LogRocket !== 'undefined') {
        LogRocket.track('ChargeReminderShown', {
          charging: this.batteryService.isCharging(),
          inactiveTime
        })
      }
    }
  }

  private showChargeNotification() {
    if (this.audioUnlocked()) {
      this.playAudio(this.chargeMeAudio())
      this.chargeMeAudioPlayed.set(true)
    }
  }

  private handleChargingChange(charging: boolean) {
    if (charging) {
      this.onChargeConnected()
    } else {
      this.checkAndNotify()
    }
  }

  private onChargeConnected() {
    if (typeof LogRocket !== 'undefined') {
      LogRocket.track('ChargerConnected', {
        charging: this.batteryService.isCharging()
      })
    }
    if (this.audioUnlocked()) {
      this.stopAllAudio()
      timer(1000)
        .pipe(take(1))
        .subscribe(() => this.playAudio(this.thankYouAudio()))
    }
    this.chargeMeAudioPlayed.set(false)
  }

  private playAudio(audioElement: HTMLAudioElement) {
    if (this.audioUnlocked() && audioElement.paused) {
      audioElement.play().catch(err => {
        console.error(err)
      })
    }
  }

  private stopAllAudio() {
    ;[this.chargeMeAudio(), this.thankYouAudio()].forEach(a => {
      if (!a.paused) {
        a.pause()
        a.currentTime = 0
      }
    })
  }
}
