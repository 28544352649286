import { Component, inject } from '@angular/core'
import { AlertState } from '../../store/alert/alert.state'
import { AlertRuleState } from '../../store/alert-rule/alert-rule.state'
import { AlertsBrowsingState } from '../../store/alerts-browsing/alerts-browsing.state'
import { ArchivedPatientState } from '../../store/archived-patient/archived-patient.state'
import { AuthState } from '../../store/auth/auth.state'
import { DepartmentState } from '../../store/department/department.state'
import { DeviceState } from '../../store/device/device.state'
import { ExportState } from '../../store/export/export.state'
import { MeasurementState } from '../../store/measurement/measurement.state'
import { PatientState } from '../../store/patient/patient.state'
import { PccState } from '../../store/pcc/pcc.state'
import { ReportState } from '../../store/report/report.state'
import { ShiftPlanerState } from '../../store/shift-planer/shift-planer.state'
import { TreatmentPlanState } from '../../store/treatment-plan/treatment-plan.state'
import { PreferenceState } from '../../store/preference/preference.state'

@Component({
  selector: 'aiomed-test-mode',
  imports: [],
  template: ``
})
export class TestModeComponent {
  protected readonly alertState = inject(AlertState)
  protected readonly alertRule = inject(AlertRuleState)
  protected readonly alertBrowsingState = inject(AlertsBrowsingState)
  protected readonly archivedPatientState = inject(ArchivedPatientState)
  protected readonly departmentState = inject(DepartmentState)
  protected readonly deviceState = inject(DeviceState)
  protected readonly exportState = inject(ExportState)
  protected readonly measurementState = inject(MeasurementState)
  protected readonly patientState = inject(PatientState)
  protected readonly pccState = inject(PccState)
  protected readonly shiftPlanerState = inject(ShiftPlanerState)
  protected readonly treatmentPlaneState = inject(TreatmentPlanState)
  public readonly auth = inject(AuthState)
  public readonly preferenceState = inject(PreferenceState)
  public readonly reportState = inject(ReportState)
}
