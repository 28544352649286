import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { map } from 'rxjs'
import { DepartmentState } from '../../store/department/department.state'
import { DepartmentType } from '../../shared/model/departments.model'

export const isAutomaticGuard: CanActivateFn = (route, state) => {
  const store = inject(Store)
  const router = inject(Router)
  return store.select(DepartmentState.departmentType).pipe(
    map(departmentType => {
      if (departmentType === DepartmentType.Automatic) {
        return true
      }
      router.navigateByUrl('/reports')
      return false
    })
  )
}
