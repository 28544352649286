import {
  APP_INITIALIZER,
  ErrorHandler,
  inject,
  NgModule,
  provideAppInitializer
} from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n'
import { registerLocaleData } from '@angular/common'
import en from '@angular/common/locales/en'
import { FormsModule } from '@angular/forms'
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BiotModule } from '../shared/modules/biot/biot.module'
import { TokenInterceptor } from '../core/interceprors/token.interceptor'
import { RequestInterceptor } from '../core/interceprors/request.interceptor'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { RootStoreModule } from '../store/root-store.module'
import { PersistenceService } from '../shared/services/persistence.service'
import { NzLayoutModule } from 'ng-zorro-antd/layout'
import * as AllIcons from '@ant-design/icons-angular/icons'
import { IconDefinition } from '@ant-design/icons-angular'
import { NZ_ICONS } from 'ng-zorro-antd/icon'
import { IconService } from '../shared/services/icon.service'
import { MomentModule } from 'ngx-moment'
import { NgxEchartsModule } from 'ngx-echarts'
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging'
import { AngularFireModule } from '@angular/fire/compat'
import { environment } from '../environments/environment'
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { HttpLoaderFactory } from '../core/helpers/translate-http-loader'
import { NzMessageService } from 'ng-zorro-antd/message'
import { provideLottieOptions } from 'ngx-lottie'
import { ServiceWorkerModule } from '@angular/service-worker'
import { ReportLoaderComponent } from './pages/reports/components/report-loader/report-loader.component'
import { ScreenSaverModalComponent } from './layouts/modals/components/screen-saver-modal/screen-saver-modal.component'
import { CompareTimesInterceptor } from '../core/interceprors/compare-times.interceptor'
import { ClockUpdateModalComponent } from './layouts/modals/components/clock-update-modal/clock-update-modal.component'
import { NzCollapseModule } from 'ng-zorro-antd/collapse'
import { ChargeNotifierDirective } from '../core/directives/charge-notifier.directive'
import { OpenFeature } from '@openfeature/web-sdk'
import { ConfigProvider } from '../core/provider/config-provider'
import { WifiInstructionComponent } from './layouts/modals/components/mobile/wifi-instruction/wifi-instruction.component'
import { SignDisclaimerComponent } from './layouts/modals/components/sign-disclaimer/sign-disclaimer.component'
import { WarningModalComponent } from './layouts/mobile-ionic-components/component/warning-modal/warning-modal.component'
import { MenuComponent } from './layouts/menu/components/menu/menu.component'
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service'

registerLocaleData(en)

export function playerFactory() {
  // add this line
  return import('lottie-web') // add this line
} // a
export function StartUpIconServiceFactory(iconService: IconService) {
  return () => iconService.loadSvgIcon()
}

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  key => antDesignIcons[key]
)

export function initializeOpenFeature(
  configProvider: ConfigProvider
): () => Promise<void> {
  return async () => {
    await configProvider.initialize()
    OpenFeature.setProvider(configProvider)
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    RootStoreModule,
    BiotModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NzLayoutModule,
    MomentModule,
    ReportLoaderComponent,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    ServiceWorkerModule.register('./sw.js', {
      enabled: window.location.hostname !== 'localhost',
      registrationStrategy: 'registerImmediately'
    }),
    ScreenSaverModalComponent,
    ClockUpdateModalComponent,
    NzCollapseModule,
    ChargeNotifierDirective,
    WifiInstructionComponent,
    SignDisclaimerComponent,
    WarningModalComponent,
    MenuComponent
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web')
    }),
    PersistenceService,
    NzNotificationService,
    NzMessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeOpenFeature,
      deps: [ConfigProvider],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_ICONS, useValue: icons },
    provideAppInitializer(() => {
      const initializerFn = StartUpIconServiceFactory(inject(IconService))
      return initializerFn()
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CompareTimesInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ]
})
export class AppModule {}
