import { Component, EventEmitter, Output } from '@angular/core'
import { NzButtonComponent } from 'ng-zorro-antd/button'
import { NzIconDirective } from 'ng-zorro-antd/icon'

@Component({
  selector: 'aiomed-clock-update-modal',
  imports: [NzButtonComponent, NzIconDirective],
  templateUrl: './clock-update-modal.component.html',
  styleUrl: './clock-update-modal.component.scss'
})
export class ClockUpdateModalComponent {
  @Output('closeModal') closeModalEmitter = new EventEmitter()
}
