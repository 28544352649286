import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { filter, lastValueFrom, map } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { Config, FeatureParams } from '../../shared/model/config'
import { ConfigService } from '../../shared/services/config.service'
import {
  EvaluationContext,
  JsonValue,
  Logger,
  Provider,
  ProviderMetadata,
  ResolutionDetails
} from '@openfeature/angular-sdk'

@Injectable({
  providedIn: 'root'
})
export class ConfigProvider implements Provider {
  name = 'ConfigProvider'
  metadata: ProviderMetadata = {
    name: this.name
  }
  private initialized = false
  private http = inject(HttpClient)
  private route = inject(ActivatedRoute)
  private configService = inject(ConfigService)
  private flags: Config

  async initialize(context?: EvaluationContext): Promise<void> {
    if (this.initialized) {
      return
    }
    this.initialized = true
    this.flags = await this.loadFlags()
    this.configService.setConfig({ config: this.flags, configured: false })

    this.route.queryParams
      .pipe(
        map(params => {
          return Object.values(FeatureParams).reduce(
            (acc, key) => {
              const val = params[key]
              if (val !== undefined && val !== null && val !== '') {
                acc[key] = val
              }
              return acc
            },
            {} as Record<string, unknown>
          )
        }),
        filter(filteredObj => Object.keys(filteredObj).length > 0)
      )
      .subscribe(filteredData => this.setConfigSetting(filteredData))
  }

  resolveBooleanEvaluation(
    flagKey: keyof typeof this.flags,
    defaultValue: boolean,
    context: EvaluationContext,
    logger: Logger
  ): ResolutionDetails<boolean> {
    const flagValue = this.getFlagValue(flagKey, defaultValue)
    return {
      value: flagValue,
      reason: 'DEFAULT'
    }
  }

  resolveStringEvaluation(
    flagKey: keyof typeof this.flags,
    defaultValue: string,
    context: EvaluationContext,
    logger: Logger
  ): ResolutionDetails<string> {
    const flagValue = this.getFlagValue(flagKey, defaultValue)
    return {
      value: flagValue,
      reason: 'DEFAULT'
    }
  }

  resolveNumberEvaluation(
    flagKey: keyof typeof this.flags,
    defaultValue: number,
    context: EvaluationContext,
    logger: Logger
  ): ResolutionDetails<number> {
    const flagValue = this.getFlagValue(flagKey, defaultValue)
    return {
      value: flagValue,
      reason: 'DEFAULT'
    }
  }

  resolveObjectEvaluation<T extends JsonValue>(
    flagKey: keyof typeof this.flags,
    defaultValue: T,
    context: EvaluationContext,
    logger: Logger
  ): ResolutionDetails<T> {
    const flagValue = this.getFlagValue(flagKey, defaultValue)
    return {
      value: flagValue,
      reason: 'DEFAULT'
    }
  }

  private getFlagValue<T>(
    flagKey: keyof typeof this.flags,
    defaultValue: T
  ): T {
    const value = this.flags[flagKey]
    return value !== undefined ? (value as T) : defaultValue
  }

  private async loadFlags(): Promise<Config> {
    try {
      return await lastValueFrom(
        this.http.get<Config>('/assets/default-config.json')
      )
    } catch (error) {
      return {} as Config
    }
  }

  private setConfigSetting(filteredData: Record<string, unknown>) {
    const flagKeys = Object.keys(filteredData)
    for (const flagKey of flagKeys) {
      switch (flagKey) {
        case FeatureParams.FeatureStrictSystemTime:
          this.flags[flagKey] = filteredData[flagKey] === 'true'
          break
        case FeatureParams.FeatureNotifierTime:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeatureNotifierTimeRepetition:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeatureOfflineDebounceTime:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeatureSessionExpirationDuration:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeatureScreenSaverDebounceTime:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeaturePccTokenRefreshTime:
          this.flags[flagKey] = Number(filteredData[flagKey])
          break
        case FeatureParams.FeatureLocalDevicePollingEndpoint:
          this.flags[flagKey] = String(filteredData[flagKey])
          break
        case FeatureParams.FeatureNotifierEnabled:
          this.flags[flagKey] = filteredData[flagKey] === 'true'
          break
        case FeatureParams.FeatureVideoTutorialControlsShown:
          this.flags[flagKey] = filteredData[flagKey] === 'true'
          break
        default:
          break
      }
    }
    this.configService.setConfig({ config: this.flags, configured: true })
  }
}
