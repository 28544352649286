<section
  [class.has-focus]="hasFocus() && hasVirtualKeyboard()"
  [class.tablet]="isMobile"
  class="screen-saver">
  <div class="screen-saver__container" [class.tablet]="isMobile">
    @if (isShowPreScreenSaverMode) {
      @if (!isMobile) {
        <div
          (click)="focusInitialPin()"
          (mousemove)="focusInitialPin()"
          [class.blue]="screenBlockedStatus === ScreenBlockedStatus.BlueBlocked"
          [class.red]="screenBlockedStatus === ScreenBlockedStatus.RedBlocked"
          class="screen-blocked">
          AiOmed
        </div>
      } @else {
        <div
          class="screen-tablet"
          (click)="focusInitialPin()"
          (mousemove)="focusInitialPin()">
          <img
            class="screen-tablet__logo"
            src="assets/images/AiOmed_logo_white.svg"
            alt="AiOmed_logo" />
          <img
            class="screen-tablet__img"
            src="assets/images/keep-us-powered.svg"
            alt="Power-Up" />
          <div class="screen-tablet__content">
            <h4 class="screen-tablet__title">Keep us powered up!</h4>
            <p class="screen-tablet__description">After use, plug me in!</p>
          </div>
        </div>
      }
    }
    @if (isAuthenticated) {
      <div class="screen-saver__code--wrapper">
        <img
          [class.tablet]="isMobile"
          class="screen-saver__icon"
          src="assets/images/AiOmed_logo_small.svg"
          alt="AiOmed-logo-small" />
        <div class="screen-saver__text">Enter pin code</div>
        <form
          (keydown)="handlerKeydownSubmit($event)"
          (ngSubmit)="onSubmit()"
          [formGroup]="pinForm"
          class="screen-saver__form">
          <div class="screen-saver__form-container">
            <input
              #pin0
              (blur)="onBlur($event, 0)"
              (focus)="onFocus($event, 0)"
              (input)="onInput($event, 0)"
              (keydown)="onKeyDown($event, 0)"
              [class.error]="!!errorMessage"
              formControlName="pin0"
              inputmode="numeric"
              maxlength="1"
              type="text"
              [class.star]="pin0.value === STAR_SYMBOL" />
            <input
              #pin1
              (blur)="onBlur($event, 1)"
              (focus)="onFocus($event, 1)"
              (input)="onInput($event, 1)"
              (keydown)="onKeyDown($event, 1)"
              [class.error]="!!errorMessage"
              formControlName="pin1"
              inputmode="numeric"
              maxlength="1"
              type="text"
              [class.star]="pin1.value === STAR_SYMBOL" />
            <input
              #pin2
              (blur)="onBlur($event, 2)"
              (focus)="onFocus($event, 2)"
              (input)="onInput($event, 2)"
              (keydown)="onKeyDown($event, 2)"
              [class.error]="!!errorMessage"
              formControlName="pin2"
              inputmode="numeric"
              maxlength="1"
              type="text"
              [class.star]="pin2.value === STAR_SYMBOL" />
            <input
              #pin3
              (blur)="onBlur($event, 3)"
              (focus)="onFocus($event, 3)"
              (input)="onInput($event, 3)"
              (keydown)="onKeyDown($event, 3)"
              [class.error]="!!errorMessage"
              formControlName="pin3"
              inputmode="numeric"
              maxlength="1"
              type="text"
              [class.star]="pin3.value === STAR_SYMBOL" />
          </div>
          @if (errorMessage) {
            <div class="error">{{ errorMessage }}</div>
          }
          @if (isShowButton) {
            <button type="submit">Submit</button>
          }
        </form>
      </div>
    }
  </div>
</section>
