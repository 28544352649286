import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { AuthState } from '../../store/auth/auth.state'
import { PreferenceState } from '../../store/preference/preference.state'

@Injectable({
  providedIn: 'root'
})
export class NotAuthenticated {
  private readonly router = inject(Router)
  private readonly authState = inject(AuthState)
  private readonly preferenceState = inject(PreferenceState)

  canActivate() {
    return this.activate()
  }

  canLoad() {
    return this.activate()
  }

  private activate() {
    if (
      this.authState.isAuthenticated() &&
      !this.preferenceState.isMfaRequired()
    ) {
      this.router.navigateByUrl('/')
      return false
    } else {
      return true
    }
  }
}
