import { NgModule } from '@angular/core'
import { provideStore, withNgxsPlugin } from '@ngxs/store'
import { NgxsDataPluginModule } from '@angular-ru/ngxs'
import { withNgxsRouterPlugin } from '@ngxs/router-plugin'
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin'
import { AuthState } from './auth/auth.state'
import { PreferenceState } from './preference/preference.state'
import { RootStore } from './root-store'
import { BackendService } from '../shared/services/backend.service'
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin'
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin'
import { environment } from '../environments/environment'
import { withNgxsLogrocketPlugin } from '../core/helpers/logrocket-ngxs.middleware'
import { StoreEventsService } from './store-events.service'
import { AlertRuleState } from './alert-rule/alert-rule.state'
import { AlertState } from './alert/alert.state'
import { AlertsBrowsingState } from './alerts-browsing/alerts-browsing.state'
import { ArchivedPatientState } from './archived-patient/archived-patient.state'
import { DeviceState } from './device/device.state'
import { ErrorState } from './error/error.state'
import { ExportState } from './export/export.state'
import { FileState } from './file/file.state'
import { InsightBrowsingState } from './insight-browsing/insight-browsing.state'
import { InsightState } from './insight/insight.state'
import { InsightsState } from './insights/insights.state'
import { MeasurementState } from './measurement/measurement.state'
import { NotificationState } from './notification/notification.state'
import { PatientLogState } from './patient-log/patient-log.state'
import { PatientState } from './patient/patient.state'
import { PccState } from './pcc/pcc.state'
import { ReportState } from './report/report.state'
import { SessionState } from './session/session.state'
import { ShiftPlanerState } from './shift-planer/shift-planer.state'
import { SignsIllnessState } from './signsIllness/signsIllness.state'
import { TaskBrowsingState } from './task-browsing/task-browsing.state'
import { TaskState } from './task/task.state'
import { TemplateAlertRuleState } from './template-alert-rule/template-alert-rule.state'
import { TreatmentPlanState } from './treatment-plan/treatment-plan.state'
import { UserState } from './user/user.state'
import { DepartmentState } from './department/department.state'

@NgModule({
  declarations: [],
  imports: [NgxsDataPluginModule.forRoot()],
  exports: [],
  providers: [
    BackendService,
    provideStore(
      [
        AuthState,
        PreferenceState,
        RootStore,
        AlertState,
        AlertsBrowsingState,
        DepartmentState,
        PatientState,
        DeviceState,
        ErrorState,
        UserState,
        FileState,
        MeasurementState,
        SessionState,
        AlertRuleState,
        NotificationState,
        InsightState,
        InsightBrowsingState,
        InsightsState,
        SignsIllnessState,
        PatientLogState,
        TaskState,
        ShiftPlanerState,
        PccState,
        TaskBrowsingState,
        ReportState,
        ExportState,
        ArchivedPatientState,
        TemplateAlertRuleState,
        TreatmentPlanState
      ],
      {
        selectorOptions: { suppressErrors: true }
      },
      withNgxsLoggerPlugin({
        disabled: true,
        collapsed: false
      }),
      withNgxsStoragePlugin({
        keys: [
          'auth.accessJwt',
          'auth.refreshJwt',
          'auth.CNAAccessJwt',
          'auth.CNARefreshJwt',
          'preference.departmentId',
          'preference.soundNotifications',
          'preference.notification',
          'preference.lg',
          'preference.mfaRequired',
          'preference.needChooseDepartment',
          'preference.department',
          'preference.deviceId',
          'preference.secondaryDevicesIds',
          'preference.screenSaver',
          'preference.deviceDirectConnection'
        ]
      }),
      withNgxsRouterPlugin(),
      withNgxsReduxDevtoolsPlugin({
        disabled: environment.production
      }),
      withNgxsLogrocketPlugin()
    ),
    StoreEventsService
  ]
})
export class RootStoreModule {}
