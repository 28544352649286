import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotAuthenticated } from '../core/guards/not-authenticated.guard'
import { IsAuthenticated } from '../core/guards/is-authenticated.guard'
import { ROUTE_DATA } from '../core/helpers/variables'
import { isAutomaticGuard } from '../core/guards/is-automatic.guard'
import { nativeDeviceGuard } from '../core/guards/native-device.guard'

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NotAuthenticated],
    canLoad: [NotAuthenticated],
    loadComponent: () =>
      import('./pages/auth/components/login/login.component').then(
        c => c.LoginComponent
      )
  },
  {
    path: 'reset',
    loadComponent: () =>
      import(
        './pages/reset-password/components/reset-password/reset-password.component'
      ).then(c => c.ResetPasswordComponent)
  },
  {
    path: 'devices',
    canLoad: [IsAuthenticated, isAutomaticGuard],
    canActivate: [IsAuthenticated, isAutomaticGuard],
    data: {
      ...ROUTE_DATA.devices
    },
    loadComponent: () =>
      import('./pages/devices/components/devices/devices.component').then(
        c => c.DevicesComponent
      )
  },
  {
    path: 'insights',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadComponent: () =>
      import('./pages/insights/components/insights/insights.component').then(
        c => c.InsightsComponent
      )
  },
  {
    path: 'patient/:patientId',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadComponent: () =>
      import('./pages/patient/component/patient/patient.component').then(
        c => c.PatientComponent
      )
  },
  {
    path: 'insight/:insightId',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadComponent: () =>
      import('./pages/insight/components/insight/insight.component').then(
        c => c.InsightComponent
      )
  },
  {
    path: 'all-patients',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.all_patients
    },
    loadComponent: () =>
      import(
        './pages/all-patients/components/all-patients/all-patients.component'
      ).then(c => c.AllPatientsComponent)
  },
  {
    path: 'reports',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    data: {
      ...ROUTE_DATA.patient
    },
    loadComponent: () =>
      import('./pages/reports/components/reports/reports.component').then(
        c => c.ReportsComponent
      )
  },
  {
    path: 'alerts',
    canLoad: [IsAuthenticated, isAutomaticGuard],
    canActivate: [IsAuthenticated, isAutomaticGuard],
    data: {
      ...ROUTE_DATA.alerts
    },
    loadComponent: () =>
      import('./pages/alerts/components/alerts/alerts.component').then(
        c => c.AlertsComponent
      )
  },
  {
    path: 'shift-planer',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadComponent: () =>
      import(
        './pages/shift-planer/components/shift-planer/shift-planer.component'
      ).then(c => c.ShiftPlanerComponent)
  },
  {
    path: 'report/:id',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadComponent: () =>
      import('./pages/report/report.component').then(c => c.ReportComponent)
  },
  {
    path: 'notifications',
    canLoad: [IsAuthenticated],
    canActivate: [IsAuthenticated],
    loadComponent: () =>
      import(
        './pages/notifications/components/notifications/notifications.component'
      ).then(c => c.NotificationsComponent)
  },
  {
    path: '',
    canLoad: [IsAuthenticated, nativeDeviceGuard],
    canActivate: [IsAuthenticated, nativeDeviceGuard],
    data: {
      ...ROUTE_DATA.patients
    },
    loadComponent: () =>
      import('./pages/patients/components/patients/patients.component').then(
        c => c.PatientsComponent
      )
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
