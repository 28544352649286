import { Injectable } from '@angular/core'
import { decodeString, encodeString } from '../../core/helpers/encription'

@Injectable()
/**
 * @deprecated Not needed as soon as we use StoragePlugin
 */
export class PersistenceService {
  code = 'zsh123456'

  constructor() {}

  set(key: string, data: any) {
    try {
      localStorage.setItem(key, JSON.stringify(encodeString(data, this.code)))
    } catch (e) {
      console.error('Error saving to LocalStorage', e)
    }
  }

  get(key: string) {
    try {
      // @ts-ignore
      return decodeString(JSON.parse(localStorage.getItem(key)), this.code)
    } catch (e) {
      console.error('Error getting data from LocalStorage', e)
      return null
    }
  }

  clear() {
    localStorage.clear()
  }
}
