import { Injectable, inject } from '@angular/core'
import { AuthState } from '../../store/auth/auth.state'
import { PreferenceState } from '../../store/preference/preference.state'
import { seRedirectDataToLocalStorage } from '../helpers/check-redirect-setting'

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticated {
  private readonly authState = inject(AuthState)
  private readonly preferenceState = inject(PreferenceState)

  canActivate() {
    return this.activate()
  }

  canLoad() {
    return this.activate()
  }

  private activate() {
    if (
      !this.authState.isAuthenticated() ||
      this.preferenceState.isMfaRequired()
    ) {
      seRedirectDataToLocalStorage()
      this.authState.logout()
      return false
    } else {
      return true
    }
  }
}
